@import 'styles/core/variables.scss';
@import 'styles/plasma-scss-overrides';

$pg-toasts: () !default;
$pg-toasts:
    (
      'success': (
        'background-color': $success-100,
        'text-color': $success-800,
        'icon-color': $success-500
      ),
      'warning': (
        'background-color': $warning-100,
        'text-color': $warning-800,
        'icon-color': $warning-500,
      ),
      'danger': (
        'background-color': $danger-100,
        'text-color': $danger-800,
        'icon-color': $danger-500,
      ),
      'info': (
        'background-color': #D6EFFF,
        'text-color': #0D469B,
        'icon-color':  $info-400
      )
);

@each $key, $value in $pg-toasts {
  .pg-toast-#{$key} {
    background-color: map-get($value, background-color);
    border-color: map-get($value, border-color);

    .pg-toast-message {
      color: map-get($value, text-color);
    }

    .pg-toast-icon {
      border: 2px solid white;
      border-radius: 50%;
      background-color: white;
      color: map-get($value, icon-color);
    }

    .description {
      font-size: .75em;
    }

    .btn-container {
      background-color: white;
      &:active {
        background-color: map-get($value, text-color);
      }
      &:hover {
        background-color: $gray-100;
      }
      button.btn-close {
        color: map-get($value, text-color);
        opacity: 1;
        background: none;
      }
    }
  }
}
