@import 'styles/core/variables.scss';

#layoutAuthentication {
  background-color: #2c87ff;
  background: linear-gradient(107.42deg, #3EB5F0 2.27%, #1B46A1 95.18%);
}

.logo {
  margin-top: 5vh;
  text-align: center;
  position: fixed;
  width: 100%;
  .pg-logo-blue {
    fill: rgba(255, 255, 255, 0.25);
  }

  .pg-logo-orange {
    fill: rgba(255, 255, 255, 0.25);
  }
}

nav {
  padding: 0 !important;
}

.back-arrow {
  max-width: 95px;
  max-height: 95px;
}

.navbar {
  &.navbar-with-back-button {
    height: 95px;
    .navbar-back {
      font-size: 20px;
      font-weight: bold;
      line-height: 20px;
      background-color: white;
      padding: 10px 20px;
      border-radius: 5px;
      color: $pgSecondary;
    }
  }
}


.nav-link {
  transition: transform 0.1s ease-in-out;
  padding: 0;
  &.active {
    transform: translate(0, -4px) scale(0.9);
  }
  &:hover {
    transform: translate(0, -4px) scale(1.1);
  }
}

.footer-text {
  font-family: 'Metropolis';
  font-size: 20px;
  text-align: center;
  color: white;
}

.footer-admin {
  padding-top: 2.5rem;
}
