import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as rxjs from 'rxjs';
import { defer, finalize, Observable, of, startWith } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MutationResult } from 'apollo-angular';
import { PgToastService } from '@common/services/pg-toast.service';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { GraphQLError } from 'graphql/error';
import { ApolloError } from '@apollo/client/errors';

export class NetworkCallOptions {
  showSpinner: boolean = true;
  showToast: boolean = true;
}

@Injectable({
  providedIn: 'root',
})
export class NetworkCallService {
  constructor(
    private readonly pgToastService: PgToastService,
    private readonly spinnerService: NgxSpinnerService
  ) {}

  private defaultNetworkCallOptions: NetworkCallOptions = new NetworkCallOptions();

  /** Call a GraphQL query the display a toast and spinner */
  queryWithToastAndSpinner<T>(callToServer: () => Observable<ApolloQueryResult<T>>, successMessage: string, errorMessage: string): Observable<ApolloQueryResult<T>> {
    return this.query(callToServer, successMessage, errorMessage, this.defaultNetworkCallOptions) as Observable<ApolloQueryResult<T>>;
  }

  /** Call a GraphQL query the display the spinner but WITHOUT showing a toast */
  queryWithSpinner<T>(callToServer: () => Observable<ApolloQueryResult<T>>): Observable<ApolloQueryResult<T>> {
    return this.query(callToServer, '', '', { showSpinner: true, showToast: false });
  }

  /** Call a GraphQL query the display a toast but WITHOUT using a spinner */
  queryWithToast<T>(callToServer: () => Observable<ApolloQueryResult<T>>, successMessage: string, errorMessage: string): Observable<ApolloQueryResult<T>> {
    return this.query(callToServer, successMessage, errorMessage, { showSpinner: false, showToast: true });
  }

  /** Call a GraphQL query without loading or spinner */
  queryInBackground<T>(callToServer: () => Observable<ApolloQueryResult<T>>): Observable<ApolloQueryResult<T>> {
    return this.query(callToServer, '', '', { showSpinner: false, showToast: false });
  }

  /** Call a GraphQL query with more granular control */
  query<T>(
    callToServer: () => Observable<ApolloQueryResult<T>>,
    successMessage: string,
    errorMessage: string,
    options: NetworkCallOptions = this.defaultNetworkCallOptions
  ): Observable<ApolloQueryResult<T>> {
    return this.call(callToServer, successMessage, errorMessage, options) as Observable<ApolloQueryResult<T>>;
  }

  /** Call a GraphQL mutate the display a toast and spinner */
  mutateWithToastAndSpinner<T>(callToServer: () => Observable<MutationResult<T>>, successMessage: string, errorMessage: string): Observable<MutationResult<T>> {
    return this.mutate(callToServer, successMessage, errorMessage, this.defaultNetworkCallOptions) as Observable<MutationResult<T>>;
  }

  /** Call a GraphQL mutate the display the spinner but WITHOUT showing a toast */
  mutateWithSpinner<T>(callToServer: () => Observable<MutationResult<T>>): Observable<MutationResult<T>> {
    return this.mutate(callToServer, '', '', { showSpinner: true, showToast: false });
  }

  /** Call a GraphQL mutate the display a toast but WITHOUT using a spinner */
  mutateWithToast<T>(callToServer: () => Observable<MutationResult<T>>, successMessage: string, errorMessage: string): Observable<MutationResult<T>> {
    return this.mutate(callToServer, successMessage, errorMessage, { showSpinner: false, showToast: true });
  }

  /** Call a GraphQL mutate without loading or spinner */
  mutateInBackground<T>(callToServer: () => Observable<MutationResult<T>>): Observable<MutationResult<T>> {
    return this.mutate(callToServer, '', '', { showSpinner: false, showToast: false });
  }

  /** Call a GraphQL mutate with more granular control */
  mutate<T>(
    callToServer: () => Observable<MutationResult<T>>,
    successMessage: string,
    errorMessage: string,
    options: NetworkCallOptions = this.defaultNetworkCallOptions
  ): Observable<MutationResult<T>> {
    return this.call(callToServer, successMessage, errorMessage, options) as Observable<MutationResult<T>>;
  }

  /**
   * Use if you want granular control of loading (e.g. you don't want to use the spinner, but instead animate a component like the lesson cards)
   * This will properly set the
   * Set the loading state of ApolloGraphQL result to true before call is made
   *
   * Once call is completed, set loading to false
   * @param networkCall - The network call to make
   * Example usage:
   *  `return this.loadingService.withLoading(() => this.getStudentActivitySearchGQL.fetch({ searchDto }, { fetchPolicy: 'network-only', errorPolicy: 'all' }));`
   */
  withLoading<T>(networkCall: () => Observable<ApolloQueryResult<T>>): Observable<ApolloQueryResult<T>> {
    return defer(() => networkCall()).pipe(
      startWith({ loading: true, data: undefined as T, errors: [], error: undefined, networkStatus: 1, partial: false }), // Initial loading state
      catchError((error) => {
        // Handle error, potentially logging or transforming the error
        return of({
          loading: false,
          data: undefined as T,
          errors: [new GraphQLError(error.message)],
          error: new ApolloError({ errorMessage: error.message }),
          networkStatus: 8,
          partial: false,
        });
      }),
      finalize(() => ({ loading: false }))
    );
  }

  errorTracer(errorMessage: string) {
    return <T extends any>(obs$: Observable<T>): Observable<T> => obs$.pipe(this.gqlErrorsToaster(errorMessage));
  }

  private call<T>(
    callToServer: () => Observable<ApolloQueryResult<T> | MutationResult<T>>,
    successMessage: string,
    errorMessage: string,
    options: NetworkCallOptions
  ): Observable<ApolloQueryResult<T> | MutationResult<T>> {
    if (options.showSpinner) {
      this.spinnerService.show();
    }
    return callToServer().pipe(
      catchError((err) => {
        if (options?.showToast) {
          this.pgToastService.showError(err);
        }
        return of(err);
      }),
      tap((result) => {
        if (result.errors && options?.showToast) {
          result.errors.forEach((err: any) => {
            this.pgToastService.showError(errorMessage);
          });
        }
        if (result?.data && options?.showToast) {
          this.pgToastService.showSuccess(successMessage);
        }
      }),
      finalize(() => {
        if (options.showSpinner) {
          this.spinnerService.hide();
        }
      })
    );
  }

  private gqlErrorsToaster(errorMessage: string) {
    return <T extends any>(obs$: Observable<T>) => {
      return obs$.pipe(
        rxjs.tap((result: any) => {
          if (result?.errors) {
            const messages = [errorMessage];

            result.errors.forEach((err: any) => {
              err.graphQLErrors?.forEach((gqlerr: any) => console.error(gqlerr?.toString()));
              if (err.networkError) {
                const { name, message, cause, stack } = err.networkError;
                messages.push([err.networkError.name, message, cause].join(','));
              }

              this.pgToastService.showError(messages.join('. '));
            });

            // return rxjs.throwError(() => new Error(result.errors))
          }
        })
      );
    };
  }
}
