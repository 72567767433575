@import 'styles/core/variables.scss';

:host {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0.5em;
  z-index: 1200;
  width: 350px;
}
