@import 'styles/core/variables.scss';

.badge {
  position: absolute;
  left: -13px;
  top: -4px;
  padding: 5px 9px;
  border-radius: 100%;
  background: #2D78DC;
  color: white;
  font-size: .875rem;
}

.dropdown-option {
  font-family: 'Metropolis';
  font-size: 1.2rem;
  font-style: normal;
  line-height: 36px;
  text-align: left;
  min-height: 1.75rem;
  display: block;
  padding: .75rem;
}

.alert-unread {
  color: $pgWarning;
  font-size: 1.5rem;
  text-align: left;
  padding-right: .75rem;
}

.alert-read {
  color: $pg-light-blue-soft;
  font-size: 1.5rem;
  text-align: left;
  padding-right: .75rem;
}

.open{
  opacity: .5;
}

.closed {
  opacity: 1;
}

