@import 'styles/core/variables.scss';
@import 'styles/plasma-scss-color-overrides';

.table-icon-btn {
  border-radius: 100%;
  background: transparent;
  border: none;
  aspect-ratio: 1;
  padding: 8px;
  width: 36px;
  transition: background-color 200ms ease;

  &.disabled{
    ::ng-deep svg {
      color: $text-100
    }
  }

  &:not(.disabled){

    &:hover,
    &:focus {
      background-color: $secondary-100;
    }

    &:active {
      background-color: $secondary-300;
    }

  }

  ::ng-deep svg {
    height: 100%;
    width: 100% !important;
    aspect-ratio: 1;
  }
}
