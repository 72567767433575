import {
  faArrowDownToLine,
  faArrowRightToLine,
  faBell,
  faCaretDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEnvelopeOpen,
  faFileMagnifyingGlass,
  faFlask,
  faMicroscope,
  faNewspaper,
  faShareNodes,
  faSliders,
  faSquareCheck,
  faTrashCan,
  // @ts-ignore
} from '@awesome.me/kit-55ca5fc4e1/icons/classic/light';

export const fontAwesomeLightIcons = {
  faArrowRightToLine,
  faArrowDownToLine,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCaretDown,
  faFileMagnifyingGlass,
  faFlask,
  faNewspaper,
  faShareNodes,
  faBell,
  faEnvelopeOpen,
  faSliders,
  faTrashCan,
  faMicroscope,
  faSquareCheck,
};
