import { DateRangeService } from './date-range.service';
import { ToastService } from './toast.service';
import { UtilityService } from './utility.service';
import { ClickTrackerService } from './click-tracker.service';
import { DateService } from '@common/services/date.service';
import { FileDownloadService } from '@common/services/file-download.service';
import { NetworkCallService } from '@common/services/network-call.service';
import { PgToastService } from '@common/services/pg-toast.service';
import { ClipboardService } from '@common/services/clipboard.service';
import { LocationService } from '@common/services/location.service';
import { DataCyService } from '@common/services/data-cy.service';
import { DeviceDetectionService } from '@common/services/device-detection.service';

export const services = [
  UtilityService,
  ToastService,
  DateService,
  DateRangeService,
  ClickTrackerService,
  FileDownloadService,
  NetworkCallService,
  PgToastService,
  ClipboardService,
  LocationService,
  DataCyService,
  DeviceDetectionService,
];

export * from './utility.service';
export * from './toast.service';
export * from './date-range.service';
export * from './date.service';
export * from './click-tracker.service';
export * from './file-download.service';
export * from './network-call.service';
export * from './pg-toast.service';
export * from './clipboard.service';
export * from './location.service';
export * from './data-cy.service';
export * from './device-detection.service';
