import {
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowRightToLine,
  faArrowsFromLine,
  faBackwardFast,
  faBars,
  faBell,
  faBookmark,
  faBookOpen,
  faBriefcase,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faChartArea,
  faChartBar,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faClipboardCheck,
  faClock,
  faCode,
  faCog,
  faColumns,
  faCopy,
  faCouch,
  faDollarSign,
  faEdit,
  faEnvelope,
  faEnvelopeDot,
  faExclamation,
  faExclamationSquare,
  faExclamationTriangle,
  faFile,
  faFileCertificate,
  faFileDownload,
  faFilePdf,
  faFlag,
  faFlask,
  faGift,
  faGlobe,
  faHiking,
  faHome,
  faHourglassHalf,
  faHouseChimney,
  faHyphen,
  faKey,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faMobileAlt,
  faMotorcycle,
  faMousePointer,
  faPaperclip,
  faPencilAlt,
  faPercentage,
  faPhotoVideo,
  faPause,
  faPlay,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuestion,
  faRightFromBracket,
  faRightToLine,
  faRotateRight,
  faSatelliteDish,
  faSearch,
  faShare,
  faShareNodes,
  faShoppingCart,
  faSolarSystem,
  faSquarePlus,
  faStar,
  faStarHalfAlt,
  faStoreAlt,
  faTable,
  faTachometerAlt,
  faTag,
  faTilde,
  faTimes,
  faTrash,
  faTriangle,
  faUndo,
  faUser,
  faUserCrown,
  faUsers,
  faUsersClass,
  faUserTie,
  faUserXmark,
  faVideo,
  faVolume,
  faX,
  faEllipsis,
  faWaveformLines,
  // @ts-ignore
} from '@awesome.me/kit-55ca5fc4e1/icons/classic/solid';

export const fontAwesomeSolidIcons = {
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowRightToLine,
  faBars,
  faBell,
  faBookmark,
  faBookOpen,
  faBriefcase,
  faCar,
  faChartArea,
  faChartBar,
  faChartPie,
  faCheck,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClock,
  faCode,
  faColumns,
  faCouch,
  faDollarSign,
  faExclamation,
  faExclamationTriangle,
  faFile,
  faFilePdf,
  faFlag,
  faGift,
  faGlobe,
  faHome,
  faLongArrowAltRight,
  faMousePointer,
  faPercentage,
  faMobileAlt,
  faMotorcycle,
  faPaperclip,
  faPause,
  faPlay,
  faPlus,
  faPlusCircle,
  faQuestion,
  faRightToLine,
  faSearch,
  faShare,
  faShareNodes,
  faShoppingCart,
  faSolarSystem,
  faStar,
  faStarHalfAlt,
  faStoreAlt,
  faTable,
  faTachometerAlt,
  faTag,
  faVideo,
  faCaretDown,
  faTimes,
  faUser,
  faPrint,
  faKey,
  faPhotoVideo,
  faUserCrown,
  faUsers,
  faUsersClass,
  faPencilAlt,
  faHiking,
  faFileCertificate,
  faUserTie,
  faHourglassHalf,
  faEdit,
  faCopy,
  faClipboardCheck,
  faCog,
  faTrash,
  faExclamationSquare,
  faFileDownload,
  faUndo,
  faMapMarkerAlt,
  faCaretLeft,
  faCaretRight,
  faTilde,
  faEnvelope,
  faHyphen,
  faFlask,
  faEnvelopeDot,
  faArrowsFromLine,
  faCircleCheck,
  faSatelliteDish,
  faRightFromBracket,
  faTriangle,
  faRotateRight,
  faBackwardFast,
  faHouseChimney,
  faSquarePlus,
  faX,
  faUserXmark,
  faVolume,
  faEllipsis,
  faWaveformLines,
};
