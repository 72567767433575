@import 'styles/core/variables.scss';

#userDropdown {
  cursor: pointer;
}

.dropdown-user-img {
  height: auto !important;
  max-height: 100px !important;
}
