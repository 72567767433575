@import 'styles/core/variables.scss';

.main-nav {
  gap: 1rem;
}

.navbar-nav.main-nav {
  justify-content: space-evenly;
}

.nav-link {
  color: rgb(44, 120, 219) !important;
}

.nav-link:hover {
  color: #1b55a2 !important;
}

.nav-link:focus {
  color: #1b55a2 !important;
}

.text-link {
  font-size: larger;
  text-transform: uppercase;
}
