/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=brands&m=free */

import {
  faBootstrap,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
  faFacebook,
  faFacebookF,
  faGithub,
  faGoogle,
  faHtml5,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faGoogleDrive,
  faMicrosoft,
  faChrome,
  faFirefox,
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faInstagramSquare,
  faYoutubeSquare,
  faPinterestSquare,
  faDiscord,
// @ts-ignore
} from '@awesome.me/kit-55ca5fc4e1/icons/classic/brands';

export const fontAwesomeBrandsIcons = {
  faBootstrap,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
  faFacebook,
  faFacebookF,
  faGithub,
  faGoogle,
  faHtml5,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faGoogleDrive,
  faMicrosoft,
  faChrome,
  faFirefox,
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faInstagramSquare,
  faYoutubeSquare,
  faPinterestSquare,
  faDiscord,
};
