@import 'styles/core/variables.scss';

pg-game-modal {
  .modal-title {
    color: $primary;
    font-weight: bold;
  }

  .modal-body {
    .alert-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card {
      margin: 3rem 8rem 8rem 8rem;
    }

    .card-body {
      text-align: center;

      p {
        margin-bottom: 0;
      }
    }
  }

  .browser-icon {
    font-size: 50px;
    margin-right: 20px;
  }

  .game-name {
    font-weight: 800;
  }
}

.modal-dialog {
  max-width: 800px !important;
}
