@import 'styles/core/variables.scss';

.footer-divider {
  background-image: url(/assets/img/home/footer-divider.png);
  background-color: #276ab3;
  background-size: 55% auto;
  min-height: 125px;
  border-color: #276ab3;
  border-bottom-width: 15px;
  border-bottom-style: solid;
}

.footer-content {
  background-color: #2c78db;
  color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  padding: 3rem;
  justify-content: flex-end;
}
.footer-title-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
}

.link-white:hover {
  color: #d9d9d9 !important;
}

.link-white:focus {
  color: #d9d9d9 !important;
}

.footer-icon-container a {
  color: white;
}

.footer-icon-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  justify-content: space-between;
}
