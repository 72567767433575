@import 'styles/core/variables.scss';

.title-color ::ng-deep .card-title {
  color: currentColor;
  font-weight: 500 !important;
  font-size: 1.2rem;
  margin-bottom: 0;
  text-align: center;
}

.title-color ::ng-deep .card-body {
  justify-content: center;
  height: 100%;
  min-width: 140px;
}

.col {
  flex-grow: 10;
}

.card-icon-aside {
  padding: 0.5rem;
  flex-grow: 1;
}
.small-icon ::ng-deep {
  font-size: 2rem !important;
  i,
  svg,
  .feather {
    height: 2rem !important;
    width: 2rem !important;
  }
}
.card {
  height: 100%;
  min-width: 140px;
}

.g-0 {
  height: 100%;
  display: flex;
}

.selected {
  transform: translateY(-0.3333333333rem);
  box-shadow: 0 0.5rem 2rem 0 rgb(33 40 50 / 25%);
}
